<template>
  <div>
    <ComHeader menuKey="news"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img
          class="banner-icon animate__animated animate__backInLeft"
          src="@/assets/images/news-banner-icon.png"
          alt=""
        />
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">新闻资讯</p>
          <p>新闻资讯，行业动态，最新政策，应用案例。</p>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container news-detail">
      <template v-for="item in newsList" :key="item.title">
        <router-link
          class="news-item"
          :style="{ visibility: item.title ? '' : 'hidden' }"
          :to="'/newsdetail/' + item.id"
        >
          <div class="news-lf">
            <img class="item-img" :src="item.imgUrl" alt="" />
          </div>
          <div class="news-rt">
            <div class="item-title">{{ item.title + item.title }}</div>
            <p class="item-txt">{{ item.content }}</p>
            <div class="item-time">
              <div class="time">{{ item.time }}</div>
              <div class="detail">查看详情 ></div>
            </div>
          </div>
        </router-link>
      </template>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    const newsList = [
      {
        id: "8",
        title: "中码科技丨以智慧科技助力“汤尤杯”赛事辉煌",
        imgUrl: require("@/assets/images/news/08/01.jpg"),
        time: "2024-05-15 13:44",
        content:
          "在成都高新体育中心举办的2024年汤尤杯羽毛球赛圆满落幕。九天的激烈角逐，为观众带来了一场场视觉盛宴。在这背后，中码科技以其卓越的技术实力和细致入微的服务，为赛事的成功举办提供了强有力的支撑。",
      },
      {
        id: "7",
        title: "第81届世界科幻大会｜可信身份认证 安全守护未来",
        imgUrl: require("@/assets/images/news/07/01.jpg"),
        time: "2023-10-24 17:58",
        content:
          "世界科幻大会是世界上最受瞩目、历史最悠久、规模和影响力最大的科幻文化主题活动，在为期5天的时间里，超2万名幻迷共聚成都，有来自全球35个国家和地区的超1200名嘉宾参会，我单位严格配合要求，健全完善安检点位建设，做好智慧安防等多项保障措施。”。",
      },
      {
        id: "6",
        title: "政策动向｜加强大型营业性演出活动规范管理",
        imgUrl: require("@/assets/images/news/06/01.png"),
        time: "2023-09-14 13:39",
        content:
          "演出举办单位面向市场公开销售的门票数量不得低于核准观众数量的85%。对其余15%的门票，应当在演出前24小时进行个人信息绑定，做到“实名绑定、实名入场”。",
      },
      {
        id: "1",
        title: "2023年成都国际车展｜多算法融合人像识别服务提供数据支撑",
        imgUrl: require("@/assets/images/news/01/640.png"),
        time: "2023-09-07 15:33",
        content:
          "多算法融合人像识别应用于第二十六届成都国际汽车展览会，它提高了识别准确性和速度，实现快速、准确、无接触的验证和登记，提供更好的展会用户体验和安全性保障。",
      },
      {
        id: "2",
        title: "政策动向｜加强电竞酒店管理未成年人保护工作",
        imgUrl: require("@/assets/images/news/02/01.png"),
        time: "2023-08-29 15:33",
        content:
          "为认真贯彻落实《中华人民共和国未成年人保护法》有关规定，根据最有利于未成年人原则，切实加强电竞酒店管理中未成年人保护工作，促进行业健康有序发展。",
      },
      {
        id: "3",
        title: "2023年凉山火把节｜Q版人证核验机赋能安保升级",
        imgUrl: require("@/assets/images/news/03/02.png"),
        time: "2023-08-22 16:14",
        content:
          "中码科技Q版人证核验机赋能凉山火把节安保升级，通过实时抓拍活体人脸图像，读取身份证芯片信息，实现人证同一性安全快速进入会场。",
      },
      {
        id: "4",
        title: "智慧核验系统助力展会安全快速通行",
        imgUrl: require("@/assets/images/news/04/01.png"),
        time: "2023-08-02 15:00",
        content:
          "可折叠立式核验机发挥出特点和优势，在展会期间提供了设备技术支持和人员管理服务，参展人员可提前预约，核验通行，有效避免通行阻塞，为展会的安全提供有力保障。",
      },
      {
        id: "5",
        title: "实我风险核验为安保公司规避用人风险",
        imgUrl: require("@/assets/images/news/05/02.png"),
        time: "2023-07-28 18:04",
        content:
          "管理规范、严格的保安公司可以最大程度给政府、企业、市民带来安全保障。人证核验机（简称小Q）系统可以协助安保公司在人员综合管理方面提质增效，助力安保公司形成管理的核心竞争力。",
      },
    ];

    return {
      newsList,
    };
  },
});
</script>

<style lang="less" scoped>
.common-banner {
  background: url("~@/assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon {
    width: 242px;
    height: 185px;
    margin-right: 50px;
  }
}
.news-detail {
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .news-item {
    margin-bottom: 80px;
    width: 100%;
    // border: 1px solid rgba(180, 180, 180, 0.45);
    // box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    &:hover {
      .news-lf {
        img {
          transform: scale(1.08);
          transition: all 0.3s;
        }
      }
    }
    .news-lf {
      flex-shrink: 0;
      width: 260px;
      height: auto;
      margin-right: 20px;
      overflow: hidden;
      .item-img {
        width: 100%;
        height: 155px;
      }
    }
    .news-rt {
      flex: 1;
      overflow: hidden;
      .item-title {
        font-weight: 600;
        font-size: 20px;
        color: #142d70;
        // text-align: center;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-txt {
        color: #8493b7;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: 20px;
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.mobile {
  .common-banner {
    height: 300px;
    padding: 50px 20px 0;
    display: flex;
    align-items: center;
    .banner-icon {
      width: 121px;
      height: auto;
      margin-right: 50px;
    }
  }
  .news-detail {
    padding: 20px 10px;
    .news-item {
      width: 100%;
      margin-bottom: 10px;
      font-size: 13px;
      // flex-direction: column;
      .news-lf {
        width: 120px;
      }
      .item-title {
        font-size: 16px;
        margin-bottom: 5px;
      }
      .item-img {
        height: 100px;
      }
    }
  }
}
</style>
